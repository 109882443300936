.challenge-buttons {
    background-color: white;
}

.challenge-button {
    cursor: pointer

    width: 100%
    height: auto
}

.challengebutton-container {
    display:inline;
    width: 50%

    .-idle {
        display:inline-block;
    }
    .-pressed {
        display:none
    }

    & + .challengebutton-container {
        margin-top: rem( 50px )

        @media ( max-width: theme.components.grid-layout.breakpoints.medium ) {
            margin-top: rem( 30px );
        }

        @media ( max-width: theme.components.grid-layout.breakpoints.small ) {
            margin-top: 0;
        }
    }

    @media ( max-height: 480px ) {
        width: 33%

        margin-top: 0 !important;
    }
}