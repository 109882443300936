.hotspot-point {
    position: absolute
    width: 2em
    height: 2em

    border: 0.2em solid theme.colors.suva-yellow
    border-radius: 100%

    background-color: theme.colors.suva-yellow-transparent;

    transform: translate( -50%, -50% );

    &.-correct {
        border-color: theme.colors.success
        background-color: alpha( theme.colors.success, 0.4 )
    }

    &.-wrong {
        border-color: theme.colors.error
        background-color: alpha( theme.colors.error, 0.4 )
    }

}