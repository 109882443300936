.intro-layout {
    //background-color: #f2f2f2;
    min-height: 100vh;
    padding-top: rem( 80px );

    @media screen and (max-width: 48rem ) {
        padding-top: 0

        .max-width-layout {
            padding: 0 rem( 20px )
        }
    }
}