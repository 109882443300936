/*
---
name: Global Reset
category: Shared
---

The lernetz css library has a set of reset style to have the same behaviour on every browser.
Furthermore this reset sets some global variables like:
 * body font-families
 * body line-height
 * body text-rendering
 * :root font-size for proper rem conversion
*/



html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
	font-size:inherit;
	vertical-align:baseline;
}

:root {
	font-size: 16px;
}

html {
	font-size: 100%;
}

body {
	line-height: theme.settings.text-line-height;
	color: theme.colors.text;
	font-family: theme.settings.text-font;
	text-rendering: theme.settings.text-rendering;
	background-color: theme.colors.body;
	-webkit-font-smoothing: antialiased;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
	display:block;
}


select {
	vertical-align:middle;
}

textarea {
	width:100%;
}

table {
	border-collapse:collapse;
	border-spacing:0;
}

//
// 1. Correct color not being inherited.
//    Known issue: affects color of disabled elements.
// 2. Correct font properties not being inherited.
// 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
//

button,
input,
optgroup,
select,
textarea {
  color: inherit; // 1
  font: inherit; // 2
  margin: 0; // 3
}


//
// Remove inner padding and border in Firefox 4+.
//

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}



/*
 * Normalize the behaviour of all browsers
 *
 * Instead of setting box-sizing fix on all elements we only set it on the root element
 * and tell all elements to inherit it's property from the parent
 * That way it's possible to overwrite the box-sizing anywhere while keeping proper inheritance
 */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
a {
	text-decoration: none;
	color: theme.colors.action;
}
img {
	display: block;
}
