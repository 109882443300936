.text-style{

    &.-h1 {
        color: theme.colors.suva-orange;
        font-family: Arial;
        font-size: rem( 48px );
        font-weight: bold;
        line-height: rem( 56px );
        margin-bottom: rem( 20px )
    }

    &.-h2 {
        color:  theme.colors.suva-gray;
        font-family: Arial;
        font-size: rem( 25px );
        line-height: rem( 35px );
        margin-bottom: rem( 20px )
    }

    &.-h3 {
        color:  theme.colors.suva-orange;
        font-family: Arial;
        font-size: rem( 18px );
        font-weight: bold;
        line-height: rem( 28px );
        margin-bottom: rem( 10px )
    }

    &.-list {
		margin-left: rem( 25px );
		padding-bottom: rem( 20px );
	}

	&.-paragraph {
		padding-bottom: rem( 20px );
	}

    &.-upper {
        text-transform: uppercase
    }

    &.-white {
        color: white;
        text-shadow: 0 2px 4px rgba(0,0,0,0.2);
    }

    @media only screen and (max-width: 48rem ) {
        &.-h1 {
            font-size: rem( 32px )
        }
    }
}