.challenge-layout {
    height: 100vh;

    display: grid;

    grid-template-areas: "message status" "content buttons";
    grid-template-rows: rem( 100px ) 1fr;
    grid-template-columns: 1fr rem( 282px );

    @media ( max-width: theme.components.grid-layout.breakpoints.medium) {
        grid-template-rows: rem( 64px ) 1fr;
        grid-template-columns: 1fr rem( 181px );
    }
}

.challenge-message {
    grid-area: message;
    -ms-grid-column: 1;
    -ms-grid-row: 1;

    background-color: theme.colors.suva-faintgray;
}
.challenge-status {
    grid-area: status
    -ms-grid-column: 2;
    -ms-grid-row: 1;

    background-color: theme.colors.suva-lightgray;
}
.challenge-content {
    grid-area: content
    -ms-grid-column: 1;
    -ms-grid-row: 2;

    background-color: theme.colors.suva-faintgray

    @media screen and ( max-width: 48rem ) {
        padding: rem( 10px ) !important
    }
}
.challenge-buttons {
    grid-area: buttons;
    -ms-grid-column: 2;
    -ms-grid-row: 2;
}