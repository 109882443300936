.status-bubble {
    background-color: theme.colors.body
    display: inline-block
    position: relative

    margin-bottom: rem( 10px );

    border-radius: rem( 2px )

    font-size: 0.8em;

    width: rem( 80px );
    text-align: center;

    transition: left 0.3s ease;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: rem( 4px ) solid transparent;
        border-top-color: theme.colors.body;
        border-bottom: 0;
        margin-left: rem( -4px );
        margin-bottom: rem( -4px );
    }

    &.-p0{
        left: 0%;

        &:after {
            left: 21%;
        }            
    }
    &.-p10{
        left: 0%;

        &:after {
            left: 42%;
        }
    }
    &.-p20{
        left: calc( 20% - 40px);
    }
    &.-p30{
        left: calc( 30% - 40px);
    }
    &.-p40{
        left: calc( 40% - 40px);
    }
    &.-p50{
        left: calc( 50% - 40px);
    }
    &.-p60{
        left: calc( 60% - 40px);
    }
    &.-p70{
        left: calc( 70% - 40px);
    }
    &.-p80{
        left: calc( 80% - 40px);
    }
    &.-p90{
        left: calc( 100% - 80px);

        &:after {
            left: 57%
        }
    }
    &.-p100{
        left: calc( 100% - 80px );

        &:after {
            left: 89%;
        }
    }

    @media ( max-width: theme.components.grid-layout.breakpoints.medium) {
        line-height: rem( 18px )
    }

}