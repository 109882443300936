.background-style {
    &.-cover {
        background-size: cover
    }

    &.-center {
        background-position: center
    }

    &.-hidden {
        background-image: none !important;
    }
}