.hide-responsive {

	conf = theme.components.grid-layout;

	for breakname, breakwidth in conf.breakpoints {
		&.-{ breakname } {
			@media all and ( max-width: breakwidth ) {
				display: none;
			}
		}
	}
}