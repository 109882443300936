.language-chooser {
    background-color: white;
    position: absolute;
    bottom: rem( 66px )

    width: rem( 127px );

    box-shadow: 0 0 2px rgba(0,0,0,0.2);

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: rem( 9px ) solid transparent;
        border-top-color: #fff;
        border-bottom: 0;
        margin-left: rem( -9px )
        margin-bottom: rem( -9px )
    }
}

.language-link {
    font-size: rem( 14px );
    line-height: rem( 16px );
    
    padding: rem( 9px ) rem( 10px );

    display: block;
    color: theme.colors.suva-gray

    &:hover {
        color:theme.colors.suva-orange
    }
}