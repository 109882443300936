.info-layer {
    position: absolute;
    background-color: theme.colors.suva-faintgray
    min-height: 100vh;
    top: 0;
    width: 100vw;

    z-index: 999;
}

.close-layer {
    cursor:pointer;
    color: theme.colors.suva-gray;

    &:hover {
        color: theme.colors.suva-orange
    }
}