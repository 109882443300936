.button-style {

    padding: rem( 7px ) rem( 16px )
    position: relative;
    width: auto;
    display: inline-block;

    border: 1px solid theme.colors.border-gray
    &:hover, &.-active {
        background-color: theme.colors.suva-orange
        border-color: theme.colors.suva-orange

        color: theme.colors.text-inverted
    }

    &.-cta {
        background-color: theme.colors.suva-blue   
        color: white
        border-width: 0
    }

    &.-with-icon {
        padding-right: rem( 16+32px )
    }

    &.-main {

        height: rem( 50px )
        width: 100%

        position:absolute
        bottom: 0

        padding: rem( 7px ) rem( 16px )

        .svg-icon {
            top: rem( 14px )
        }
    }

    &.-small {
        width: rem( 150px );
        heigth: rem( 36px );

        .svg-icon {
            top: rem( 7px );
        }
    }

    .svg-icon {
        position: absolute
        top: rem( 8px )
        right: rem( 16px )
    }

    &:hover {
        .svg-icon.-moveable {
            transition-duration: 0.15s
            right: rem( 7px )
        }
    }
}