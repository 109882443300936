.intro-footer {
    width: 100%;
    height: rem( 50px );
    background-color: white;    
    z-index: 10
    margin-top: 2rem

    @media screen and ( max-width: 48rem ) {
        padding: 0 rem(20px)
    }
}