/*!
---
name: Colors
category: Shared/Variables
template: colors
tag:
 - variables
 - colors
colors:
 - variable: "text-color"
   description: "The default text color on the body"
   value: "#3F3F40"
 - variable: "text-inverted-color"
   description: "The default inverted text color on the body"
   value: "#FFF"
 - variable: "action-color"
   description: "The main action color used for links and buttons"
   value: "#EC6449"
 - variable: "body-color"
   description: "The main background color"
   value: "#FFF"
 - variable: "enabled-color"
   description: "Signalcolor for enabled/ok/success/true - search for a better name"
   value: "#7CAE7A"
 - variable: "disabled-color"
   description: "Signalcolor for disabled/notok/error/false - search for a better name"
   value: "#EC6449"
 - variable: "outline-navigation-color"
   description: "Color for the elements in the outline navigation"
   value: "lighten( #3F3F40, 60 )"
---

The color variables that are used throughout the library. 
*/
theme.colors = {

  suva-gray: #666666,
  suva-orange: #FF8200,
  suva-blue: #00b8cf,
  suva-green: #c1e200,
  suva-red: #eb0064,
  suva-yellow: #fce300,
  suva-yellow-transparent: rgba( 252, 227, 0, 0.4 ),
  suva-lightgray: #e0e0e0,
  suva-faintgray: #F5F5F6,

  text: #666,
  text-inverted: #FFF,
  text-gray: #666,

  border-gray: #CCC,

  action: #00b8cf,

  body: #FFF,

  line: #F5F5F6,

  error: #eb0064,
  success: #c1e200,
}
