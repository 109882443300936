theme = { components: {} };

@import "./lib/stylus/variables/*.styl";
@import "./lib/stylus/components/**/*.var.styl";

@import "./variables/**/*.styl";
@import "./components/**/*.var.styl";

@import "./lib/stylus/shared/**/*.styl";
@import "./lib/stylus/components/**/*.styl";

@import "./components/*.styl";
@import "./custom/*.styl";

.hidden {
    display: none;
}