.feedback-panel {

    height: 100%;

    &.-neutral {
        //background-color: theme.colors.suva-gray;
        //color: theme.colors.text-inverted;
    }

    &.-correct {
        background-color: theme.colors.success;
    }

    &.-wrong {
        background-color: theme.colors.error;

        color: theme.colors.text-inverted;
    }

    // mobile fixes
    @media screen and (max-width: 48rem ) {
        padding: rem( 5px ) !important;
        line-height: 1em !important;
    }
}