.module-card {
    overflow hidden
    height: rem( 260px );

    cursor:pointer;

    transition: all 0.2s ease;

    &:hover {
        box-shadow: rem(0px) rem(1px) rem(6px) rem(0px) rgba(0,0,0,0.37);
    }
}