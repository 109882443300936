.h-stretch {
    width: 100%;
}

.v-stretch {
    height: 100%;
}

.no-overflow {
    overflow: hidden
}