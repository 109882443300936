.status-bar {
    background-color: theme.colors.body

    height: rem( 13px )
    border-radius: rem( 13px/2 )

    .progress {
        background-color: theme.colors.suva-orange
        height: rem( 13px )
        border-radius: rem( 13px / 2 )

        transition: all 0.3s ease;

    }

    &.-p0 .progress{
        width: 5%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p10 .progress{
        width: 10%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p20 .progress{
        width: 20%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p30 .progress{
        width: 30%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p40 .progress{
        width: 40%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p50 .progress{
        width: 50%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p60 .progress{
        width: 60%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p70 .progress{
        width: 70%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p80 .progress{
        width: 80%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p90 .progress{
        width: 90%;
        border-top-right-radius: 0
        border-bottom-right-radius: 0
    }
    &.-p100 .progress{
        width: 100%;
    }

}