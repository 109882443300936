.box-layout {
    background-color: white

    max-width: rem( 560px );

    margin-top: rem( 170px );

    @media screen and ( max-width: 48rem ), screen and ( max-height: 500px ) {
        z-index: 100;
        margin-top: 0 !important
        position: absolute 
        top: 50%
        transform: translateY( -50% ) !important
        width: 100%
    }
}   