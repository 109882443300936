.certificate-layout {
    margin-left: rem( 30px );
    margin-right: rem( 30px );

    padding-top: rem( 120px );
}

.certificate-title {
	color: #FF8200;
	font-family: Arial;
	font-size: rem( 48px );
	font-weight: bold;
	line-height: rem( 56px );
}

.certificate-name {
	color: #666666;
	font-family: Arial;
	font-size: rem( 30px );
	line-height: rem( 40px );
	font-weight: normal
}

.certificate-rules {
    border-top:rem( 2px ) solid #FF8200;
    border-bottom: rem( 2px ) solid #FF8200;

    margin-top: rem( 98px );

    font-size: rem( 10px );
    line-height: rem( 13px );

}

.certificate-rule {
    margin-bottom: rem( 10px )
}

.certificate-claim {
	color: #00B8CF;
	font-family: Arial;
	font-size: rem( 14px );
	font-style: italic;
	font-weight: bold;
	line-height: rem( 16px );
}