.svg-icon {
	&.-sub {
		vertical-align: sub
	}

	&.-logo {
		//hard coded
		width: rem( 64px );
		position relative
		top: rem( -9px )
	}
}